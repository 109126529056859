import jwtDecode, { JwtPayload } from 'jwt-decode'
import { getTime } from '../helpers'

export function validTimeRemainingFor(token?: string): number {
  if (!token) {
    return -1
  }
  
  let decoded: JwtPayload = jwtDecode(token)
  if (!decoded.exp) {
    return -1
  }
  
  return decoded.exp - getTime()
}
