import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../store"
import { useEffect } from "react"
import { fetchData } from "../../store/ad"
import { Ad } from "../../types"
import { NavLink } from "react-router-dom"

function AdInfo(params: { ad: Ad }) {
  let { ad } = params
  return (
    <ul>
      <li>title: {ad.title}</li>
      <li>mode: {ad.mode}</li>
      <li>file: {ad.filename}</li>
    </ul>
  )
}

function AdInfoScreen(params: { adId: string }) {
  let dispatch = useAppDispatch()
  let store = useSelector((state: RootState) => state.ad)
  
  useEffect(() => {
    dispatch(fetchData({ id: params.adId }))
  }, [dispatch])
  
  return (
    <div>
      <h3>Ad Info Screen { params.adId }</h3>
      <NavLink to={`/ads/${params.adId}/edit`}>Edit Ad</NavLink>
      { store.isLoading ? "Loading.." : "" }
      { store.data && !store.isLoading ? <AdInfo ad={store.data} /> : "" }
    </div>
  )
}

export default AdInfoScreen
