let AUTH_TOKEN = 'atk'
let REFRESH_TOKEN = 'rtk'

export class AuthState {
  authToken?: string
  refreshToken?: string
  
  load() {
    this.authToken = localStorage.getItem(AUTH_TOKEN) ?? undefined
    this.refreshToken = localStorage.getItem(REFRESH_TOKEN) ?? undefined
    console.log('State load')
  }
  
  save() {
    if (this.authToken) {
      localStorage.setItem(AUTH_TOKEN, this.authToken)
    } else {
      localStorage.removeItem(AUTH_TOKEN)
    }
    
    if (this.refreshToken) {
      localStorage.setItem(REFRESH_TOKEN, this.refreshToken)
    } else {
      localStorage.removeItem(REFRESH_TOKEN)
    }
  }
}

let authState = new AuthState()
authState.load()

export default authState
