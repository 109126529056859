import { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import RootScreen from './root.screen'

import authStateStorage from '../../lib/auth-state'
import api from '../../lib/api'
import { Account } from '../../types'
import { validTimeRemainingFor } from '../../lib/utils/jwt'
import config from '../../config'


function AuthenticatedScreen() {
  let [ state, setState ] = useState({
    isLoaded: false,
    account: undefined as Account | undefined,
  })
  
  let [ authState, setAuthState ] = useState({
    authToken: authStateStorage.authToken,
    refreshToken: authStateStorage.refreshToken,
  })
  
  let [ needsAuthCheck, setNeedsAuthCheck ] = useState(true)
  let [ needsAuthRefresh, setNeedsAuthRefresh ] = useState(false)
  let [ needsAccountReload, setNeedsAccountReload ] = useState(false)
  
  
  useEffect(() => {
    api.authToken = authState.authToken
    api.refreshToken = authState.refreshToken
  }, [authState])
  
  
  useEffect(() => {
    let authRefreshCheckTimer = setInterval(() => setNeedsAuthCheck(true), 60*1000)//once a minute
    
    return function cleanup() {
      clearInterval(authRefreshCheckTimer)
    }
  }, [])
  
  
  useEffect(() => {
    if (!needsAuthCheck) { return }
    
    setNeedsAuthCheck(false)
    
    let refreshTimeRemaining = validTimeRemainingFor(authState.refreshToken)
    if (refreshTimeRemaining < 0) {
      setAuthState({ authToken: undefined, refreshToken: undefined })
      setState({ isLoaded: true, account: undefined })
      return
    }
    
    let authTimeRemaining = validTimeRemainingFor(authState.authToken)
    if (authTimeRemaining < config.authTokenRefreshTimeBefore) {
      setNeedsAuthRefresh(true)
      return
    }
    
    if (!state.account) {
      setNeedsAccountReload(true)
    }
  }, [needsAuthCheck])
  
  
  useEffect(() => {
    if (!needsAuthRefresh) { return }
    
    setNeedsAuthRefresh(false)
    
    api.authRefresh().then(
      (result) => {
        let authToken = result.authToken
        authStateStorage.authToken = authToken
        authStateStorage.save()
        
        setAuthState((authState) => {
          return { authToken, refreshToken: authState.refreshToken }
        })
        setNeedsAuthCheck(true)
      },
      (error) => {
        if (error.status === 401) {
          authStateStorage.authToken = undefined
          authStateStorage.refreshToken = undefined
          authStateStorage.save()
          
          setAuthState({ authToken: undefined, refreshToken: undefined })
        } else {
          toast.error(error.message)
        }
        
        setState({ isLoaded: true, account: undefined })
      }
    )
  }, [ needsAuthRefresh ])
  
  
  useEffect(() => {
    if (!needsAccountReload) { return }
    
    setNeedsAccountReload(false)
    
    setState((state) => {
      return { isLoaded: false, account: state.account }
    })
    
    api.authCheck().then(
      (result) => {
        setState({ isLoaded: true, account: result })
      },
      (error) => {
        if (error.status === 401) {
          setNeedsAuthRefresh(true)
        } else {
          toast.error(error.message)
        }
      }
    )
  }, [ needsAccountReload ])
  
  
  if (!state.isLoaded) {
    return (<div>Loading...</div>)
  }
  
  if (!state.account) {
    return (<Navigate to='/auth' />)
  }
  
  return (
    <RootScreen account={state.account} />
  )
}

export default AuthenticatedScreen
