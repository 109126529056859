import { Controller, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import api from '../../lib/api'
import { Ad, AdMode } from '../../types'
import { FileUpload } from '../common/file-upload'

interface AdFormData {
  ad: Partial<Ad>
}


let AdInfoForm = (params: { ad: Ad | undefined, onUpdate: (adId: string) => void, onCancel: () => void }) => {
  let defaultValues: AdFormData = {
    ad: params.ad ?? { mode: AdMode.moving, isActive: true },
  }
  
  let { control, setError, setValue, handleSubmit, formState: { errors } } = useForm({ defaultValues, mode: 'onBlur' })
  
  let onSubmit = async (data: AdFormData) => {
    let ad = data.ad
    if (!ad) {
      setError('ad', new Error('No ad given'))
      return
    }
    
    if (ad.duration) { ad.duration = +ad.duration }
    
    let adId = params.ad?.id
    try {
      if (adId) {
        await api.adUpdate(adId, ad)
        toast.success('Ad updated')
        params.onUpdate(adId)
      } else {
        let data = await api.adCreate(ad)
        toast.success('New ad created')
        params.onUpdate(data.id)
      }
    } catch (e: any) {
      toast.error(`An error occured ${e.message}`)
    }
  }
  
  return (
    <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
      <Controller name='ad.title' control={control} rules={{ required: true }} render={({ field: { value, onChange, onBlur } }) => (
        <p>
          Title:<br />
          <input value={value} onBlur={onBlur} onChange={onChange} />
          { errors.ad?.title ? `${errors.ad?.title?.message}` : null }
        </p>
      )} />
      
      <Controller name='ad.mode' control={control} rules={{ required: true }} render={({ field: { value, onChange, onBlur } }) => (
        <p>
          Mode:<br />
          <select value={value} onBlur={onBlur} onChange={onChange}>
            { Object.values(AdMode).map(mode => <option key={mode} value={mode}>{mode}</option>) }
          </select>
        </p>
      )} />
      
      <Controller name='ad.duration' control={control} rules={{ required: true }} render={({ field: { value, onChange, onBlur } }) => (
        <p>
          Duration:<br />
          <input value={value} onBlur={onBlur} onChange={onChange} />
          { errors.ad?.duration ? errors.ad?.duration?.message : null }
        </p>
      )} />
      
      <Controller name='ad.filename' control={control} rules={{ required: true }} render={({ field: { value } }) => (
        <p>
          {value}
          <FileUpload onUploaded={ fullFileKey => { console.log(fullFileKey); setValue('ad.filename', fullFileKey) } } />
          { errors.ad?.filename ? errors.ad?.filename?.message : null }
        </p>
      )} />
      { errors.ad?.filename ? <p>{errors.ad?.filename?.message}</p> : null }
      
      
      <Controller name='ad.isActive' control={control} render={({ field: { value, onChange, onBlur } }) => (
        <p>
          <input type='checkbox' checked={value} onChange={onChange} onBlur={onBlur} />Live (ad will be displayed on devices when live)
        </p>
      )} />
      
      {
        errors.ad
        ? <p>An error occured {JSON.stringify(errors.ad ?? {})}</p>
        : null
      }
      
      <input type='submit' value={defaultValues.ad.id ? 'Submit update' : 'Create new ad'} />
      <input type='button' value='Cancel' onClick={() => params.onCancel()} />
    </form>
  )
}

export default AdInfoForm
