import { useNavigate } from 'react-router-dom'

import LoginForm from '../components/auth/login-form'

import state from '../lib/auth-state'

function LoginScreen() {
  let navigate = useNavigate()
  
  return (
    <LoginForm
      onAuth={ (authToken: string, refreshToken: string) => {
        state.authToken = authToken
        state.refreshToken = refreshToken
        state.save()
        
        navigate('/')
      }}
    />
  )
}

export default LoginScreen
