import { useState } from 'react'
import { toast } from 'react-toastify'

import api from '../../lib/api'

function LoginForm(props: {
  onAuth: (authToken: string, refreshToken: string) => void
}) {
  let [ login, setLogin ] = useState("")
  let [ password, setPassword ] = useState("")
  let [ isLoading, setIsLoading ] = useState(false)
  
  return (
    <div>
      <h4>Adcar login</h4>
      <form onSubmit={e => {
        e.preventDefault()
        
        if (isLoading) { return }
        
        setIsLoading(true)
        api.authLogin({ login, password }).then(
          (result) => {
            setIsLoading(false)
            props.onAuth(result.authToken, result.refreshToken)
          },
          (error) => {
            setIsLoading(false)
            toast.error(`Error: ${error.message}`)
          }
        )
      }}>
        <p>
          Login
          &nbsp;
          <input type='text' value={login} onChange={e => setLogin(e.target.value)} />
        </p>
        <p>
          Password
          &nbsp;
          <input type='password' value={password} onChange={e => setPassword(e.target.value)} />
        </p>
        <p>
          {
            isLoading
              ? "Logging in.."
              : <input type='submit' value='Login' />
          }
        </p>
      </form>
    </div>
  )
}

export default LoginForm
