import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../store"
import { useEffect } from "react"
import { fetchData } from "../../store/ad"
import AdInfoForm from "../../components/ads/ad-info-form"
import { useNavigate } from "react-router-dom"

function AdEditScreen(params: { adId: string }) {
  let dispatch = useAppDispatch()
  let store = useSelector((state: RootState) => state.ad)
  let navigate = useNavigate()
  
  useEffect(() => {
    dispatch(fetchData({ id: params.adId }))
  }, [dispatch])
  
  return (
    <div>
      <h3>Ad Edit Screen { params.adId }</h3>
      { store.isLoading ? "Loading.." : "" }
      {
        store.data && !store.isLoading
          ? <AdInfoForm
              ad={store.data}
              onUpdate={(adId: string) => navigate(`/ads/${adId}`)}
              onCancel={() => navigate(`/ads/${params.adId}`)}
            />
          : ""
      }
    </div>
  )
}

export default AdEditScreen
