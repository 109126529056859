import { useDispatch } from 'react-redux'
import { configureStore } from '@reduxjs/toolkit'

import device from './device'
import dashboardImpressions from './dashboard-impressions'
import dashboardAds from './dashboard-ads'
import ads from './ads'
import ad from './ad'
import territories from './territories'

export const store = configureStore({
  reducer: {
    device,
    dashboardImpressions,
    dashboardAds,
    ads,
    ad,
    territories,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export const useAppDispatch = () => useDispatch<AppDispatch>()
