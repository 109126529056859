import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'

import { store } from './store'
import { Provider } from 'react-redux'

import LoginScreen from './screens/login.screen'
import AuthenticatedScreen from './screens/service/authenticated.screen'
import { Toaster } from 'react-hot-toast'

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/auth" element={<LoginScreen />} />
            <Route path="/*" element={<AuthenticatedScreen />} />
          </Routes>
        </BrowserRouter>
        <Toaster position='top-right' />
      </div>
    </Provider>
  )
}

export default App
