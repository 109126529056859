import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../store"
import AdInfoForm from "../../components/ads/ad-info-form"
import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { resetToCreate } from "../../store/ad"

function AdCreateScreen() {
  let dispatch = useAppDispatch()
  let store = useSelector((state: RootState) => state.ad)
  let navigate = useNavigate()
  
  useEffect(() => {
    dispatch(resetToCreate())
  }, [dispatch])
  
  return (
    <div>
      <h3>Ad Create Screen</h3>
      {
        store.data && !store.isLoading
          ? <AdInfoForm
              ad={store.data}
              onUpdate={(adId: string) => navigate(`/ads/${adId}`)}
              onCancel={() => navigate(`/ads`)}
            />
          : ""
      }
    </div>
  )
}

export default AdCreateScreen
