import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../store"
import { useEffect } from "react"
import { fetchData } from "../../store/ads"
import { Ad } from "../../types"
import { NavLink } from "react-router-dom"

function AdItem(params: { ad: Ad }) {
  return (
    <li>
      <NavLink to={`/ads/${params.ad.id}`}>{params.ad.title}</NavLink>
    </li>
  )
}


export default function AdsScreen() {
  let dispatch = useAppDispatch()
  let store = useSelector((state: RootState) => state.ads)
  
  useEffect(() => {
    dispatch(fetchData({ page: 0, itemsPerPage: 5 }))
  }, [dispatch])
  
  return (
    <div>
      <h3>Ads</h3>
      <p>
        <NavLink to={`/ads/new`} reloadDocument>Add New</NavLink>
      </p>
      { store.isLoading ? "Loading.." : "" }
      <ul>
        {
          store.data && !store.isLoading
            ? store.data.map(ad => <AdItem ad={ad} key={`ads-item-${ad.id}`} />)
            : ""
        }
      </ul>
    </div>
  )
}
