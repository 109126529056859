import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../store"
import { useEffect } from "react"
import { fetchData } from "../../store/dashboard-ads"
import { Ad } from "../../types"
import { NavLink } from "react-router-dom"

function AdItem(params: { ad: Ad }) {
  return (
    <li>
      <NavLink to={`/ads/${params.ad.id}`}>{params.ad.title}</NavLink>
    </li>
  )
}


export function DashboardAds() {
  let dispatch = useAppDispatch()
  let store = useSelector((state: RootState) => state.dashboardAds)
  
  useEffect(() => {
    dispatch(fetchData({ page: 0, itemsPerPage: 5 }))
  }, [dispatch])
  
  return (
    <div>
      <h3>Dashboard ads</h3>
      { store.isLoading ? "Loading.." : "" }
      <ul>
        { store.data.map(ad => <AdItem ad={ad} key={`dash-ads-item-${ad.id}`} />) }
      </ul>
    </div>
  )
}
