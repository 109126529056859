import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import { RootState, useAppDispatch } from "../store"
import { fetchData } from "../store/dashboard-impressions"

import { DashboardMap, DashboardAds } from "../components/dashboard"

import { dayStartOf } from "../utils/date"

function DashboardScreen() {
  let dispatch = useAppDispatch()
  let [date, setDate] = useState(dayStartOf(new Date()))
  
  let impressionsStore = useSelector((state: RootState) => state.dashboardImpressions)
  
  useEffect(() => {
    dispatch(fetchData({ date: date, adId: undefined }))
  }, [dispatch, date])
  
  return (
    <div>
      <h3>Dashboard</h3>
      <div>
        <h4>{date.toISOString()}</h4>
        <select onChange={ e => {
          let daysCount = +e.target.value
          setDate(dayStartOf(new Date(new Date().getTime() - 24 * 60 * 60 * 1000 * daysCount)))
        } }>
          <option value={0}>Today</option>
          <option value={1}>Yesterday</option>
        </select>
        {
          impressionsStore.isLoading ? "Loading.." : ""
        }
      </div>
      <DashboardMap />
      <DashboardAds />
    </div>
  )
}

export default DashboardScreen
