import { Account } from '../types'
import authState from '../lib/auth-state'
import api from '../lib/api'

function doLogout() {
  authState.authToken = undefined
  authState.refreshToken = undefined
  authState.save()
  
  api.authToken = undefined
  api.refreshToken = undefined
  
  window.location.reload()
}


function UserPanel(props: { account: Account }) {
  return (
    <div className='user-panel'>
      <span>User #{props.account.id}</span>
      &nbsp;&nbsp;
      <span>({props.account.kind})</span>
      &nbsp;&nbsp;
      <button onClick={doLogout}>Logout</button>
    </div>
  )
}

export default UserPanel
