import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { Ad } from '../types'
import api from '../lib/api'

interface DataParams {
  page: number
  itemsPerPage: number
}

export let fetchData = createAsyncThunk('ads/fetchData', async (params: DataParams) => {
  return await api.adsList(params)
})

export let slice = createSlice({
  name: 'ads',
  initialState: {
    data: [] as Ad[],
    isLoading: true,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchData.fulfilled, (state, action) => {
        state.data = action.payload.items
        state.isLoading = false
      })
      .addCase(fetchData.pending, (state, _action) => {
        state.isLoading = true
      })
      .addCase(fetchData.rejected, (state, _action) => {
        state.isLoading = false
      })
  }
})

export default slice.reducer
