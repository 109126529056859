import { useState } from "react"
import { useDropzone } from "react-dropzone"
import { uploadAd } from "../../lib/s3-upload"
import toast from "react-hot-toast"

export function FileUpload(props: { onUploaded: (fullFileKey: string) => void, existingFilename?: string }) {
  let [uploadingFile, setUploadingFile] = useState<File | undefined>(undefined)
  
  let { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: async (acceptedFiles: File[]) => {
      let acceptedFile = acceptedFiles.pop()
      if (!acceptedFile) { return }
      
      setUploadingFile(acceptedFile)
      
      try {
        let result = await uploadAd(acceptedFile)
        props.onUploaded(result.fullKey)
      } catch (err) {
        toast.error('Error uploading file ' + err)
      }
      
      setUploadingFile(undefined)
    }
  })
  
  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      { uploadingFile ? null : <input {...getInputProps()} /> }
      <div>
        <div>
          {
            props.existingFilename
            ? `Uploaded video: <b>${props.existingFilename}</b>`
            : null
          }
          {
            uploadingFile
            ? `Uploading ${uploadingFile.name}..`
            : "Drop your video here or click to upload."
          }
        </div>
      </div>
    </div>
  )
}