import axios from 'axios'
import FormData from 'form-data'

import api from './api'
import { PresignedS3Url } from '../types'

export async function uploadRecord(file: File): Promise<{ fullKey: string }> {
  let extension = file.name.split('.').pop() ?? 'mp4'
  let presignedUrl: PresignedS3Url = await api.recordsPresignUpload(extension)
  return await upload({ file, presignedUrl })
}


export async function uploadAd(file: File): Promise<{ fullKey: string }> {
  let extension = file.name.split('.').pop() ?? 'mp4'
  let presignedUrl: PresignedS3Url = await api.adsPresignUpload(extension)
  return upload({ file, presignedUrl })
}


async function upload(params: { file: File, presignedUrl: PresignedS3Url }): Promise<{ fullKey: string }> {
  let { file, presignedUrl } = params
  let form = new FormData()
  Object.entries(presignedUrl.fields).forEach(([field, value]) => {
    form.append(field, value)
  })
  
  form.append('file', file)
  
  await axios.post(presignedUrl.url, form, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  
  let { fullKey } = presignedUrl
  return { fullKey }
}
