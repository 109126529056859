let config = {
  apiServerEnv: 'prod',
  
  ymapsKey: process.env.YMAPS_KEY ?? '57d4451b-74c1-41aa-9a3a-d83ac07ead2f',
  
  authTokenRefreshTimeBefore: 10 * 60, //10 minutes
  
  itemsPerPage: 10,
}

export default config
