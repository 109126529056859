import config from '../config'

import { Ad, PaginationParams, RecordFileKind } from '../types'

//TODO: replace custom requester with axios or something

enum Env {
  local = "local",
  http = "http",
  prod = "prod",
}

function server(env: Env) {
  switch (env) {
    case Env.local: return "http://localhost:3000"
    case Env.http: return "http://api.adcar.ai"
    case Env.prod:
    default: return "https://api.adcar.ai"
  }
}

enum RequestAuthMode {
  anonymous, authorized, refresh
}


export class ApiAdcar {
  env: Env
  baseUrl: string
  authToken?: string
  refreshToken?: string

  constructor() {
    this.env = (config.apiServerEnv as Env) || Env.prod
    this.baseUrl = server(this.env)
  }

  authCheck() {
    return this.post('/auth/check')
  }

  authRefresh() {
    return this.post('/auth/refresh', { token: this.refreshToken }, RequestAuthMode.refresh)
  }

  authLogin(params: { login: string, password: string }) {
    return this.post('/auth/login', params, RequestAuthMode.anonymous)
  }
  
  recordsList(params: PaginationParams) {
    return this.get('/records', {
      ...this.requestParamsForPagination(params),
      'filter[order]': 'id DESC',
    })
  }
  
  record(id: number) {
    return this.get(`/records/${id}`)
  }
  
  recordsPresignUpload(fileExtension: string) {
    return this.post('/records/presign-upload', { fileExtension })
  }
  
  recordsPresignDownload(params: { recordId: number, fileKind: RecordFileKind }) {
    return this.post(`/records/${params.recordId}/presign-download/${params.fileKind}`)
  }
  
  recordsPresignDownloadByKey(fileKey: string) {
    return this.post('/records/presign-download-by-key', { fileKey })
  }
  
  
  devicesList(_params: {}) {
    return this.get('/devices')
  }
  
  deviceLocationPacksList(params: { deviceIds: string[] }) {
    return this.get('/devices/location-packs', params)
  }
  
  
  impressionsByDevice(params: { deviceId: string, date: Date }) {
    return this.get(`/impressions/device/${params.deviceId}`, { date: params.date.toISOString() })
  }
  
  impressionsByDate(params: { date: Date, adId?: string }) {
    let dateFrom = params.date
    let dateTo = new Date(dateFrom.getTime() + 24 * 60 * 60 * 1000)
    
    let requestParams: object = {
      'filter[where][shownAt][between][0]': dateFrom,
      'filter[where][shownAt][between][1]': dateTo,
      'filter[order]': 'id DESC',
    }
    
    if (params.adId) {
      requestParams = {
        ...requestParams,
        'filter[where][adId]': params.adId,
      }
    }
    
    return this.get(`/impressions`, requestParams)
    
    // return this.get(`/impressions/date`, { date: params.date.toISOString() })
  }
  
  impressions(params: { adId: string } & PaginationParams) {
    return this.get(`/impressions`, {
      ...this.requestParamsForPagination(params),
      'filter[where][adId]': params.adId,
      'filter[order]': 'id DESC',
    })
  }
  
  
  adsList(params: PaginationParams) {
    return this.get('/ads', {
      ...this.requestParamsForPagination(params),
      // 'filter[order]': 'id DESC',
    })
  }
  
  adsPresignUpload(fileExtension: string) {
    return this.post('/ads/presign-upload', { fileExtension })
  }
  
  adCreate(ad: Partial<Ad>) {
    return this.post('/ads', ad)
  }
  
  adUpdate(adId: string, ad: Partial<Ad>) {
    return this.put(`/ads/${adId}`, ad)
  }
  
  adGet(adId: string) {
    return this.get(`/ads/${adId}`)
  }
  
  
  territoriesList(params: PaginationParams) {
    return this.get('/territories', {
      ...this.requestParamsForPagination(params),
      // 'filter[order]': 'id DESC',
    })
  }
  
  
  
  //MARK: -
  
  requestParamsForPagination(params: PaginationParams): object {
    let itemsPerPage = params.itemsPerPage ?? config.itemsPerPage
    return {
        'filter[limit]': itemsPerPage,
        'filter[skip]': itemsPerPage * params.page,
    }
  }
  
  pageCountFor(itemsCount: number, itemsPerPage: number = config.itemsPerPage): number {
    return 1 + Math.floor((itemsCount - 1) / itemsPerPage)
  }

  get(endpoint: string, params?: object, mode: RequestAuthMode = RequestAuthMode.authorized) {
    //TODO: rewrite that shit
    let url = this.baseUrl + endpoint
    if (params) {
      const keys = Object.keys(params)
      url += "?" + keys.flatMap(k => {
        let value = (params as any)[k]
        if (Array.isArray(value)) {
          return value.map(q => k + '[]=' + q)
        } else {
          return [k + "=" + (params as any)[k]]
        }
      }).join("&")
    }
    return this.fetchURL(url, mode)
  }

  post(endpoint: string, json: object = {}, mode: RequestAuthMode = RequestAuthMode.authorized) {
    return this.jsonRequest("POST", endpoint, mode, json)
  }

  put(endpoint: string, json: object = {}, mode: RequestAuthMode = RequestAuthMode.authorized) {
    return this.jsonRequest("PUT", endpoint, mode, json)
  }

  del(endpoint: string, json: object = {}, mode: RequestAuthMode = RequestAuthMode.authorized) {
    return this.jsonRequest("DELETE", endpoint, mode, json)
  }

  jsonRequest = (method: string, endpoint: string, mode: RequestAuthMode, json: object) => {
    return this.fetch(this.baseUrl + endpoint, {
      method,
      headers: {
        "Content-Type": "application/json",
        ...this.headers(mode)
      },
      body: JSON.stringify(json)
    })
  }

  fetchURL = (url: string, mode: RequestAuthMode) => {
    return this.fetch(url, {
      headers: this.headers(mode),
    })
  }

  async fetch(url: string, options: object) {
    const response = await fetch(url, options)

    if (!response.ok) {
      let errorMessage = response.statusText
      if (isJSON(response)) {
        const json = await response.json()
        errorMessage = describeError(json)
      }
      throw new ApiError(response.status, errorMessage)
    }

    if (!isJSON(response)) {
      throw new Error('Malformed response')
    }

    return await response.json()
  }

  headers(mode: RequestAuthMode): object {
    switch (mode) {
      case RequestAuthMode.anonymous: return {}
      case RequestAuthMode.authorized: return this.authToken ? { Authorization: `Bearer ${this.authToken}` } : {}
      case RequestAuthMode.refresh: return this.refreshToken ? { Authorization: `Bearer ${this.refreshToken}` } : {}
    }
  }
}

function describeError(obj: any): string {
  const keys = Object.keys(obj)
  if (obj.message) {
    return obj.message
  } else if (obj.error) {
    return describeError(obj.error)
  } else if (keys.length) {
    return keys.map(k => {
      const v = obj[k]
      return k + " - " + (Array.isArray(v) ? v[0] : v)
    })[0]
  } else {
    // The worst case
    return "API Error"
  }
}


class ApiError extends Error {
  status: number

  constructor(status: number, message: string) {
    super(message)
    this.status = status
    this.name = this.constructor.name
  }
}

function isJSON(response: Response) {
  const contentType = response.headers.get("content-type")
  return contentType && contentType.includes("application/json")
}

const api = new ApiAdcar()
export default api
