import dateformat from 'dateformat'

export function getTime(): number {
  return Math.floor(+ new Date() / 1000)
}

export function dateForTime(time: number): Date {
  return new Date(time * 1000)
}

export function dateString(date: Date): string {
  return dateformat(date, 'HH:MM dd.mm.yyyy')
}

export function dayStartOf(date: Date): Date {
  let result = new Date(date)
  result.setUTCHours(0)
  result.setUTCMinutes(0)
  result.setUTCSeconds(0)
  return result
}
