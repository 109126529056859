import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'

import { Ad } from '../types'
import api from '../lib/api'

interface DataParams {
  id: string
}

export let fetchData = createAsyncThunk('ad/fetchData', async (params: DataParams) => {
  return await api.adGet(params.id)
})

export let resetToCreate = createAction('ad/resetToCreate')

let initialState = {
  data: undefined as Ad | undefined,
  isLoading: true,
  params: { id: "" } as DataParams,
}

let creationState = {
  data: {} as Ad,
  isLoading: false,
  params: {} as DataParams,
}

export let slice = createSlice({
  name: 'ad',
  initialState,
  reducers: {
    resetToCreate: () => creationState,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchData.fulfilled, (state, action) => {
        state.data = action.payload
        state.isLoading = false
        state.params = action.meta.arg
      })
      .addCase(fetchData.pending, (state, _action) => {
        state.isLoading = true
      })
      .addCase(fetchData.rejected, (state, _action) => {
        state.isLoading = false
      })
  }
})

export default slice.reducer
