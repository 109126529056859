import { Routes, Route, useParams } from 'react-router-dom'
import { Fragment } from 'react'

import UserPanel from '../../components/user-panel'
import MenuPanel from '../../components/menu-panel'
import DashboardScreen from '../dashboard.screen'
import AdsScreen from '../ads/ads.screen'
import PaymentsScreen from '../payments.screen'
import AccountScreen from '../account.screen'
import TerritoriesScreen from '../territories/territories.screen'

import { Account } from '../../types'
import AdInfoScreen from '../ads/ad-info.screen'
import AdEditScreen from '../ads/ad-edit.screen'
import AdCreateScreen from '../ads/ad-create.screen'

function RootScreen(props: { account: Account }) {
  return (
    <Fragment>
      <UserPanel account={props.account} />
      <MenuPanel />
      <div id="screen-container">
        <Routes>
          <Route path={`/ads/new`} element={<AdCreateScreen />} />
          <Route path={`/ads/:adId/edit`} element={<AdEditWrapper />} />
          <Route path={`/ads/:adId`} element={<AdInfoWrapper />} />
          <Route path={`/ads`} element={<AdsScreen />} />
          <Route path={`/payments`} element={<PaymentsScreen />} />
          <Route path={`/account`} element={<AccountScreen />} />
          <Route path={`/territories`} element={<TerritoriesScreen />} />
          <Route path={`/`} element={<DashboardScreen />} />
        </Routes>
      </div>
    </Fragment>
  )
}

function AdInfoWrapper() {
  let { adId } = useParams()
  if (!adId) { return <Fragment /> }
  return <AdInfoScreen adId={adId} />
}

function AdEditWrapper() {
  let { adId } = useParams()
  if (!adId) { return <Fragment /> }
  return <AdEditScreen adId={adId} />
}

export default RootScreen
