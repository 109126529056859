import { NavLink } from 'react-router-dom'


function MenuPanel() {
  return (
    <div id='menu-panel'>
      <ul>
        <li><NavLink to={`/`}>Dashboard</NavLink></li>
        <li><NavLink to={`/ads`}>Ads</NavLink></li>
        <li><NavLink to={`/territories`}>Territories</NavLink></li>
        <li><NavLink to={`/payments`}>Payments</NavLink></li>
        <li><NavLink to={`/account`}>Account</NavLink></li>
      </ul>
    </div>
  )
}

export default MenuPanel
