import { useSelector } from "react-redux"
import { RootState, useAppDispatch } from "../../store"
import { useEffect } from "react"
import { fetchData } from "../../store/territories"
import { Territory } from "../../types"
import { NavLink } from "react-router-dom"

function TerritoryItem(params: { territory: Territory }) {
  return (
    <li>
      <NavLink to={`/territories/${params.territory.id}`}>{params.territory.title}</NavLink>
    </li>
  )
}


export default function TerritoriesScreen() {
  let dispatch = useAppDispatch()
  let store = useSelector((state: RootState) => state.territories)
  
  useEffect(() => {
    dispatch(fetchData({ page: 0, itemsPerPage: 10 }))
  }, [dispatch])
  
  return (
    <div>
      <h3>Territories</h3>
      { store.isLoading ? "Loading.." : "" }
      <ul>
        {
          store.data && !store.isLoading
            ? store.data.map(territory => <TerritoryItem territory={territory} key={`territories-item-${territory.id}`} />)
            : ""
        }
      </ul>
    </div>
  )
}
