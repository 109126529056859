import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { Impression } from '../types'
import api from '../lib/api'

interface DataParams {
  date: Date
  adId: string | undefined
}

export let fetchData = createAsyncThunk('dashboard-impressions/fetchData', async (params: DataParams) => {
  return await api.impressionsByDate(params)
})

export let slice = createSlice({
  name: 'dashboard-impressions',
  initialState: {
    data: [] as Impression[],
    isLoading: true,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchData.fulfilled, (state, action) => {
        state.data = action.payload.items
        state.isLoading = false
      })
      .addCase(fetchData.pending, (state, _action) => {
        state.isLoading = true
      })
      .addCase(fetchData.rejected, (state, _action) => {
        state.isLoading = false
      })
  }
})

export default slice.reducer
